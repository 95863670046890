<template>
  <div class="Users">
    <h1 class="text-h5 grey--text text-uppercase">
      Users
    </h1>
  </div>
</template>

<script>
export default {
  name: "Users",

  components: {
    //
  },

  mounted() {
    //
  },
};
</script>

<style scoped>
.Users {
  padding: 20px;
}
</style>
